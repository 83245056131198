import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Brand from 'components/elements/Vectors/Brand'

// Images
import Circle from 'img/circle.svg'

// Video
import { MutableRefObject } from 'hoist-non-react-statics/node_modules/@types/react'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  @media (min-width: 1200px) {
    height: 80vh;
    min-height: 700px;
    max-height: 700px;
  }

  @media (min-width: 992px) {
    margin-bottom: 7.5rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 5.5rem;
  }
`

const BannerWrapper = styled.div`
  position: relative;
`

const Row = styled.div`
  padding-top: 7rem;
`

const ImageWrapper = styled.div`
  margin-top: 1rem;

  @media (min-width: 992px) {
    right: -169px;
  }

  @media (max-width: 991px) {
    right: 0;
    text-align: center;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 603px;
    width: 696px;
  }

  @media (max-width: 991px) {
    width: 70%;
    margin-bottom: 3rem;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & ul {
    list-style: none;
    li {
      position: relative;
      margin-left: 0.25rem;
      margin-bottom: 1rem;
      font-size: ${({ theme }) => theme.font.size.big};
      &:before {
        position: absolute;
        left: -35px;
        top: 0;
        content: url(${Circle});
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (min-width: 992px) {
    padding-right: 9rem;
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 2.5rem;
      font-size: ${({ theme }) => theme.font.size.huge} !important;
      line-height: ${({ theme }) => theme.font.size.mega} !important;
    }
  }

  @media (max-width: 991px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 1.5rem;
    }
  }

  @media (min-width: 576px) {
    line-height: ${({ theme }) => theme.font.size.larger};
  }
`

const BannerImage: React.FC<BannerProps> = ({ fields }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Section className="position-relative">
      <BannerWrapper>
        <div className="container py-5">
          <Row className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row ">
                <div className="col-lg-5 d-flex justify-content-center justify-content-lg-end order-lg-2">
                  <ImageWrapper className="position-relative">
                    <motion.div
                      onHoverStart={() => setHovered(true)}
                      onHoverEnd={() => setHovered(false)}
                      initial={{ scale: 1 }}
                      animate={hovered ? { scale: 1.05 } : { scale: 1 }}
                    >
                      <Image alt="banner" image={fields?.image} />
                    </motion.div>
                  </ImageWrapper>
                </div>
                <div className="col-lg-7 order-lg-1">
                  <Content content={fields.description} />
                </div>
              </div>
            </div>
          </Row>
        </div>
      </BannerWrapper>
    </Section>
  )
}

export default BannerImage
